import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../../components/contact-info/contact-info";
import SEO from "../../../components/seo/seo";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import showMktoForm from "../../../helpers/showMktoForm";
import List from "../../../components/custom-widgets/list";

const SecurityFraud = () => {
  const title = "Business Security & Fraud Prevention";
  const description =
    "Find out how WaFd Bank can protect your business with our offerings for business security and fraud prevention. Contact a commercial banker today.";
  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/security-fraud/hero-security-fraud-01202023-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/security-fraud/hero-security-fraud-01202023-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/security-fraud/hero-security-fraud-01202023-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/security-fraud/hero-security-fraud-01202023-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/security-fraud/hero-security-fraud-01202023-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/security-fraud/hero-security-fraud-01202023-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/commercial-banking/treasury-management-services/security-fraud/hero-security-fraud-01202023-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/treasury-management-services/security-fraud"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-security-fraud-01202023.jpg"
      }
    ]
  };
  const heroChevronData = {
    id: "security-fraud-hero",
    ...getHeroImgVariables(imgData),
    altText: "Two business men working on laptop in the office",
    chevronBgClass: "bg-info",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            text: "Protect Your Business and Get Peace of Mind",
            class: "text-white"
          }
        },
        {
          id: 3,
          button: {
            type: "button",
            id: "security-fraud-hero-cta",
            class: "btn-white",
            text: "Contact a Commercial Banker",
            onClick: () => showMktoForm(1067)
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      url: "/commercial-banking/treasury-management-services",
      title: "Treasury Management"
    },
    {
      id: 3,
      active: true,
      title: "Security & Fraud"
    }
  ];
  const benefitsData = {
    greenCircleCheckmark: true,
    items: [
      {
        id: 1,
        text: "Mitigate risk of check fraud"
      },
      {
        id: 2,
        text: "Reduce risk of unauthorized ACH transactions"
      },
      {
        id: 3,
        text: "Increased internal controls for businesses"
      },
      {
        id: 4,
        text: "Peace of mind"
      }
    ]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Security &amp; Fraud Prevention</h1>
        <h3>
          Business account fraud is on the rise, statistics show U.S. Businesses will lose on average 5% of gross
          revenues to fraud. At WaFd Bank we believe prevention is the best approach. Below are some of the solutions
          available to help protect your business.
        </h3>
      </section>
      <section className="container p-0">
        <div className="row">
          <div className="col-md-6">
            <StaticImage
              src="../../../images/thumbnail-security-fraud-prevention-120823.jpg"
              alt="Woman smiling while hearing music"
              placeholder="blurred"
              className="mb-3"
              quality="100"
            />
          </div>
          <div className="col-md-6">
            <p className="text-green-60 font-weight-bold">Benefits of Wafd Bank's Fraud Protection Services Include:</p>
            <List {...benefitsData} />
          </div>
        </div>
      </section>
      <section className="container">
        <h4 className="font-weight-semi-bold">
          Prevent fraud before it happens with WaFd Bank's Positive Pay options:
        </h4>
        <div className="row">
          <div className="col-md-4">
            <h5 className="text-green-60">Check Positive Pay</h5>
            <p>
              How does it work? By providing a file of checks issued, the positive pay system will compare checks
              clearing your account to the list of items issued. Whether you are using Payee Positive Pay or Traditional
              Positive Pay, item validation at the teller window is included, stopping fraud before it occurs.
            </p>
          </div>
          <div className="col-md-4">
            <h5 className="text-green-60"> Check Validation</h5>
            <p>
              For our small business clients, instead of providing a list of issued items, Check Validation (aka.
              Reverse Positive Pay) reports all checks that are presented for payment as exceptions for you to review
              and decide if they should be paid or returned.
            </p>
          </div>
          <div className="col-md-4">
            <h5 className="text-green-60">ACH Positive Pay</h5>
            <p>
              You set the rules. As ACH items are presented, you determine if the item is ok to pay. Set your rules for
              one-time transactions or recurring. Have an account which should not receive electronic payments? Let us
              know and we can block all electronic items from posting to your account.
            </p>
          </div>
        </div>
      </section>
      <ContactInfo type="commercial" />
    </SecondaryLanding>
  );
};
export default SecurityFraud;
